export interface Student {
  id: number;
  name: string;
  email: string;
  assignmentsAttended: number;
  performanceMetrics: number;
}

export enum GradingStatus {
  INCORRECT = 'INCORRECT',
  CORRECT = 'CORRECT',
  PARTIAL = 'PARTIALLY_CORRECT'
}

export interface QuestionMarks {
  questionId: string;
  marks: number;
  gradingStatus: GradingStatus;
}
export interface MarksChartProps {
  questionMarks: QuestionMarks[];
  isLoading?: boolean;
}

export interface MarkCardProps {
  questionNumber: number;
  status: GradingStatus;
}

export interface LineGraphMetricsProps {
  title: string;
  subTitle?: string;
  primaryValue: number[];
  secondaryValue: number[];
  labels: string[];
  primaryColor: string;
  secondaryColor: string;
}
export interface OverallPerfomanceProps {
  details: any;
  isLoading: boolean;
}

export type SkillType = {
  skillId: string;
  skillName: string;
  successRate: string;
};

export interface StudentPerfomanceGraphProps {
  date: { startDate: Date; endDate: Date };
  studentId: string;
  className?: string;
  subjectId: string;
}

export interface HomeworkTableProps {
  date: { startDate: Date; endDate: Date };
  studentId: string;
  subjectId: string;
}
